a {
    color: white;
    text-decoration: none;
}

.course-search {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 12px 0;
}

.course-search ul {
    list-style: none;
    padding: 0;
}

.course-search li {
    width: 600px;
    max-width: 85vw;
    padding: 30px 20px;
    margin: 14px 0;

    background-color: #31312e;
    border-radius: 6px;
    box-shadow: 0px 1px 5px rgba(115, 117, 119, 0.5);
}

.course-code {
    color: rgb(155, 119, 255);
    font-weight: 600;
    margin: 0;
    margin-bottom: 10px;
}

#loading-text {
    margin: 0;
}

.disabled {
    opacity: 0.2;
    pointer-events: none;
}

#course-search-bar {
    width: 400px;
    max-width: 85vw;
    padding: 8px 16px;
    font-size: 18px;
    line-height: 20px;
    color: white;
    vertical-align: middle;
    background-color: #272822;
    border: 1px solid white;
    border-radius: 6px;
    outline: none;
    box-shadow: rgba(225, 228, 232, 0.2) 0px 1px 0px 0px inset;
    
}

#course-search-bar:focus {
    border-color: rgb(155, 119, 255);
}

#load-more {
    height: 40px;
    width: 100px;

    color: #31312e;
    font-weight: 600;
    
    border: none;
    border-radius: 6px;
    background-color: rgb(155, 119, 255);
}

#load-more:hover {
    box-shadow: 0px 1px 8px rgba(155, 119, 255, 0.5);
}

#school-select {
    width: 434px;
    max-width: 85vw;
    margin-bottom: 12px;
    padding: 8px 16px;
    font-size: 18px;
    line-height: 20px;
    color: white;
    vertical-align: middle;
    background-color: #272822;
    border: 1px solid rgb(155, 119, 255);
    border-radius: 6px;
    outline: none;
    box-shadow: rgba(225, 228, 232, 0.2) 0px 1px 0px 0px inset;
}